import {
  convertToCamelCaseFromUnderScore,
  DATE_FORMATS,
  formatDate
} from '../../utils';

export const HeaderConfig = [
  {
    label: 'Ticket ID',
    key: 'id',
    onClick: true,
    render: (data, onClick) => {
      return (
        <span className='clickable' onClick={() => onClick(data, 'view')}>
          {data.id}
        </span>
      );
    }
  },
  {
    label: 'Created On',
    key: 'createdAt',
    render: data => {
      return (
        <span>
        {data.createdAt &&
            formatDate(data.createdAt, DATE_FORMATS['DD-MM-YYYY'])}
        </span>
      );
    }
  },
  {
    label: 'Service Engineer',
    key: 'serviceEngineer'
  },
  {
    label: 'Defect',
    key: 'defectCode'
  },
  {
    label: 'Action',
    key: 'actionCode'
  },
  {
    label: 'Status',
    key: 'status',
    render: data => {
      return <span>{getStatus(data)}</span>;
    }
  }
];

export const SERVICE_LABEL_MAP = {
  firstService: '1st Service',
  secondService: '2nd Service',
  thirdService: '3rd Service',
  fourthService: '4th Service'
};

export const getStatus = data => {
  const { underWarranty, status, serviceEngineer, dateOfInspection } = data;
  if (
    status === 'OPENED' &&
    underWarranty &&
    serviceEngineer &&
    dateOfInspection
  ) {
    return 'JI Date fixed';
  } else if (status === 'OPENED' && underWarranty && serviceEngineer) {
    return 'Engineer Assigned';
  } else if (status === 'OPENED' && underWarranty) {
    return 'Acknowledged';
  } else if (status === 'OPENED') {
    return 'Open';
  } else {
    return convertToCamelCaseFromUnderScore(status);
  }
};
