export const convertCamelCaseToDisplayText = txt => {
  let strTmp = txt.replace(/([a-z])([A-Z])/g, '$1 $2');
  if (strTmp && strTmp.length > 0) {
    strTmp = `${strTmp[0].toUpperCase()}${strTmp.slice(1)}`;
  }
  return strTmp;
};

export const convertToCamelCaseFromUnderScore = str => {
  return convertCamelCaseToDisplayText(
    (str || '').toLowerCase().replace(/_+([a-z])/g, (m, w) => w.toUpperCase())
  );
};

export const splitFileName = fileName => {
  const splits = fileName.split('?');

  if (splits && splits.length > 0) {
    return splits[0];
  }
  return fileName;
};

export const DATE_FORMATS = {
  'DD.MM.YYYY': 'DD.MM.YYYY',
  'DD-MM-YYYY': 'DD-MM-YYYY',
  'DD.MM.YYYY hh:MM': 'DD.MM.YYYY hh:MM',
  'DD MMM YY hh:MM': 'DD MMM YY hh:MM',
  'MMM DD YYYY hh:MM': 'MMM DD YYYY hh:MM',
  'YYYY-MM-DD': 'YYYY-MM-DD'
};

export const formatDate = (isoDateString, type) => {
  if (!isoDateString) {
    return '-';
  }
  try {
    const dateObj = new Date(isoDateString);
    if (Number.isNaN(dateObj.getTime())) {
      return '-';
    }
    switch (type) {
      case 'dateString':
        return isoDateString === '' ? '-' : dateObj.toLocaleDateString();
      case 'timeString':
        return dateObj.toLocaleTimeString();
      case DATE_FORMATS['YYYY-MM-DD']: {
        const date = new Date(dateObj);
        const month = `${appendZeroToTime(date.getMonth() + 1)}`;
        const monthDisplay = `${month.slice(-2)}`;
        const day = `${appendZeroToTime(date.getDate())}`;
        const dayDisplay = `${day.slice(-2)}`;
        return [date.getFullYear(), monthDisplay, dayDisplay].join('-');
      }
      case DATE_FORMATS['DD-MM-YYYY']: {
        const date = new Date(dateObj);
        const month = `${appendZeroToTime(date.getMonth() + 1)}`;
        const monthDisplay = `${month.slice(-2)}`;
        const day = `${appendZeroToTime(date.getDate())}`;
        const dayDisplay = `${day.slice(-2)}`;
        return [dayDisplay, monthDisplay, date.getFullYear()].join('-');
      }
      default:
        return `${dateObj.toLocaleDateString()} 
                  ${dateObj.toLocaleString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                  })}`;
    }
  } catch (err) {
    return 'Invalid Date';
  }
};


export const appendZeroToTime = number => {
  if (number >= 0 && number < 10) {
    return `0${number}`;
  }
  return number;
};