/** @format */

export const REST_URLS = {
  LOGIN: '/v1/oems/login',
  TICKETS: '/v1/oems/list-tickets',
  COMPLAINT: '/v1/complaintForms',
  LIST_OEMS: '/v1/oems/list-oems',
  LIST_COMPLAINT_CODES: '/v1/oems/list-complaint-codes',
  REFRESH_TOKEN: '/v1/auth/refresh-tokens',
  UPLOAD_FILES: '/v1/misc/upload/attachment/',
};

export const HOSTNAME = 'https://simpcare-api-core-prod.proudgrass-33c1f821.eastasia.azurecontainerapps.io';
