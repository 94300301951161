import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import { Button } from "@mui/material";
import "./index.scss";
import SiTable from "../../core/table";
import { HeaderConfig } from "./config";
import { getOfflineData } from "../../utils/offline-services";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import ViewTicket from "./view-ticket";

const PastTickets = () => {
  const navigate = useNavigate();
  const [pastTickets, setPastTickets] = useState({});
  const [viewTicket, setViewTicket] = useState(null);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt"
  });

  useEffect(() => {
    if (!getOfflineData("user")) {
      navigate("/login");
    } else {
      loadData(filters);
    }
  }, []);

  const loadData = (filtersObj) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.TICKETS}`,
      null,
      filtersObj
    ).then(response => {
      if (response.results) {
        setPastTickets(response);
      }
    });
  };
  
  const getComplaintDetails = data => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.COMPLAINT}/${data.complaintRecord}`,
      null
    ).then(response => {
      if (response) {
        setViewTicket({
          ...response,
          caseId:data.id
        });
      }
    });
  };

  const onRowClick = (data, type) => {
    if (type === "view") {
      getComplaintDetails(data);
    } else {
      navigate(`/edit/${data.complaintRecord}`);
    }
  };

  return (
    <div className='past-tickets-container'>
      <div className='create-new'>
        <span className='label'>View Tickets</span>
        <Button
          variant='contained'
          color='primary'
          onClick={() => navigate("/create")}
        >
          Create new Ticket
        </Button>
      </div>
      <SiTable
        header={HeaderConfig}
        data={pastTickets.results || []}
        pageCount={pastTickets.totalPages}
        onClick={onRowClick}
        onChange={(event, page) => {
          setFilters({
            ...filters,
            page
          });
          loadData({
            ...filters,
            page
          });
        }}
      ></SiTable>
      <Drawer anchor='right' open={!!viewTicket}>
        <ViewTicket
          details={viewTicket || {}}          
          onClose={() => setViewTicket(null)}
        />
      </Drawer>
    </div>
  );
};

export default PastTickets;
